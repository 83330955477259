import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const AddTestCasesToCycle = () => {
  return (
    <LayoutComponent>
      <h2>Adding Test Cases to a Test Cycle</h2>

      <p>
        Test cases can be added using test suites, and additional configurations
        like environments and tester assignments can be made.
      </p>

      <h3>Steps to Add Test Cases to a Test Cycle:</h3>
      <ol>
        <li>
          Navigate to the <strong>Test Cycles</strong> main page and select the
          test cycle you want to add test cases to.
        </li>
        <li>
          Open the <strong>Test Cases</strong> tab of the selected test cycle.
        </li>
        <li>
          Click on <strong>+ Test Cases</strong> to open the modal for adding
          test cases.
        </li>
        <li>
          In the modal, select the test suit(s) you want to use and click 'Add'.
        </li>
        <li>
          All the approved test cases within the selected test suite will be
          added to the test cycle for execution.
        </li>
      </ol>

      <div className="testimonials">
        <h5>Note</h5>
        <p>
          Only approved test cases can be added to a test cycle. If a test case
          is not approved, it can not be executed.
        </p>
      </div>

      <p>
        <strong>Assigning Environments & Testers:</strong> You can assign an
        environment and a tester to each test case individually or in bulk for
        all test cases in the cycle.
      </p>

      <h5>Next Steps</h5>
      <p>
        Once the test cases are added, you can further configure and execute the
        test cycle by going to the <strong>Execution</strong> page using the
        'Run' button.
      </p>
    </LayoutComponent>
  )
}

export default AddTestCasesToCycle
